export default {
	intro: {
		header: 'Сервіс для продажу квитків на ваші події',
		analytics: 'Аналітика та звіти\n в telegram бот',
		tickets: 'Перевірка та\nактивація квитків',
		money: 'Виведення грошей на картку',
		notifications: 'Збір бази\nучасників',
		fb: 'Розширена анкета реєстрації',
		promocode: 'Промокоди та складні знижки'
	},
	howItWorks: {
		title: 'Як це працює?',
		items: [
			{
				header: 'Створюйте та налаштовуйте свої події',
				text:
					'Сервіс дозволяє вам створити адаптивну сторінку вашої події з різними типами квитків за лічені хвилини. Також ви можете самостійно додавати членів команди до адміністрування та керувати їх доступом до змін в події. А наш телеграм бот надсилає вам повідомлення про купівлю кожного квитка 24/7.',
				list: ['Різні типи квитків', 'Телеграм бот', 'Необмежена кількість організаторів події']
			},
			{
				header: 'Просувайте та рекламуйте свої події',
				text:
					'Наша система дозволяє створювати акції з промокодами та складними знижками для просування вашої події. А також дозволяє відстежувати звідки прийшли покупці, зберігає список незавершених платежів з контактами для опрацювання клієнта та автоматично нагадує про незавершену купівлю квитка через 15 хвилин.',
				list: [
					'Створення промокодів та групових знижок',
					'Підключення Google Analytics/ Facebook Pixel/tksource',
					'Автоматичне відстеження з якого сайту прийшли покупці'
				]
			},
			{
				header: 'Працюйте зі своєю аудиторією',
				text:
					'У вас буде таблиця учасників з усіма даними. Є можливість додати будь-які додаткові поля в реєстраційну форму. Наприклад поля: "посада", "компанія", тощо. Також ви можете створити тип квитка, який вимагатиме вашого підтвердження учасника перед його отриманням.',
				list: [
					'Розширена анкета реєстрації',
					'Експорт списку учасників в csv та xlsx',
					'Можливість відбору учасників'
				]
			}
		]
	},
	pricing: {
		title: 'Цінова політика',
		items: [
			{
				header: 'Безкоштовно для організаторів події',
				text: 'Весь функціонал та підтримку ви отримуєте без місячної абонплати чи підписки'
			},
			{
				header: 'Квитки на безкоштовні заходи безкоштовні',
				text:
					'Якщо квиток на захід безкоштовний ми не беремо жодної комісії і все одно надаємо весь функціонал 😁'
			},
			{
				header: 'Низька комісія за продаж платних квитків',
				text: 'Сумарна комісія складає 5% (якщо вартість квитка 100 грн, то ви отримуєте 95 грн)'
			}
		]
	},
	aboutUs: {
		title: 'Найкращий помічник проведення заходу',
		text:
			'The Vertical – це платформа для організації продажів заходу, в якій ми врахували найкращий досвід та відгути десятків організаторів, щоб зробити зручний та корисний сервіс для вас.'
	},
	advantages: {
		title: 'Переваги',
		header: 'Переваги роботи із нами',
		items: [
			{
				header: 'Підтримка\n вас з усіх питань по сервісу',
				text: 'Ми супроводжуємо організатора від першої сторінки до останнього відвідувача'
			},
			{
				header: 'Швидкий старт\nпродажу квитків',
				text: 'Зазвичай старт продажів можливий від 1-2 годин до 3 робочих днів'
			},
			{
				header: 'Швидке отримання грошей\n з продажу квитків',
				text: 'Організатор отримує гроші протягом трьох банківських днів з моменту продажу квитка'
			}
		]
	},
	startCooperate: {
		header: 'Почніть продавати квитки із The Vertical. та заробляти гроші вже зараз!',
		btn: 'Почати зараз'
	}
}
