import error from './error'
import event from './event'
import footer from './footer'
import landing from './landing'
import languages from './languages'
import postersPage from './posters_page'
import searchPage from './search_page'
import sign from './sign'
import user from './user'

export default {
	sign,
	error,
	event,
	landing,
	user,
	languages,
	searchPage,
	postersPage,
	footer,
	goFuck: 'Русский корабль, иди нах*й!',
	helpArmy: 'Помощь украинской армии',
	showBy: 'Показать по',
	show: 'Показать',
	copy: 'Скопировать',
	copied: 'Скопировано',
	aliasDescr1: 'Вы можете указать уникальный alias и давать ссылки людям, в facebook и т.д. в виде:',
	aliasDescr2: 'Это удобно, так как люди будут видеть только ваши события.',
	learnMore: 'Узнать больше',
	subscribeForm: {
		header: 'Получайте письма от The Vertical, чтобы всегда быть в курсе обновлений!',
		success: 'Благодарим за подписку 😁'
	},
	ticketActivation: {
		title: 'Активация билета',
		ticketCreated: 'Билет оформлен',
		order: 'Заказ'
	},
	toHome: 'На главную',
	cancel: 'Отменить',
	notifications: {
		smsSent: 'SMS успешно отправлено.',
		saved: 'Сохранено',
		error: 'Произошла ошибка!'
	},
	verify: {
		sendEmailAgain: 'Отправить письмо снова',
		verificatePhone: 'Подтвердить номер телефона',
		emailVerificated: 'Поздравляем, email подтвержден!',
		emailSended: 'Письмо отправлено',
		email: 'Подтвердите ваш email.',
		phone: 'Подтвердите ваш номер телефона'
	},
	changeLang: 'Изменить язык',
	terms: {
		title: 'Правила сервиса',
		tabs: {
			organizer: 'Для организаторов',
			customer: 'Для покупателей'
		}
	},
	policy: {
		title: 'Политика конфиденциальности'
	},
	cookie: {
		text:
			'Используя The Vertical, вы соглашаетесь с нашей политикой в отношении файлов cookie. <0>Узнать больше.</0>',
		accept: 'Принять'
	},
	send: 'Отправить',
	remove: 'Удалить',
	download: 'Скачать',
	weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
}
